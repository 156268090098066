import React, { useState, useEffect } from "react";
import courseInfo from "../courseInfo.json";
import posthog from "posthog-js";
import { updateGPTViews, getGPTViews } from "../Firebase";
import "../App.css";
import NavBar from "./NavBar";

export default function Home({}) {
  posthog.init("phc_fYjJNtYIAPAr9PIbqmZSf8myr8ZeW1FHa6nyM31423c", {
    api_host: "https://app.posthog.com",
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [courses, setCourses] = useState(courseInfo);

  function handleSearchChange(event) {
    setSearchTerm(event.target.value.toLowerCase());
  }
  useEffect(() => {
    const fetchViews = async () => {
      let updatedCourses = { ...courseInfo };

      // Assuming Firebase structure allows fetching all views in one go
      const viewsSnapshot = await getGPTViews();
      console.log(viewsSnapshot);

      // Merge Firebase data with local JSON
      for (let key in updatedCourses) {
        let courseTitle = updatedCourses[key].title;
        if (viewsSnapshot[courseTitle]) {
          console.log(courseTitle);
          updatedCourses[key].views = viewsSnapshot[courseTitle].view;
        }
      }
      setCourses(updatedCourses);
    };

    fetchViews();
  }, []);

  const filteredCourses = Object.keys(courses).filter((key) => {
    const course = courseInfo[key];
    return (
      course.title.toLowerCase().includes(searchTerm) ||
      course.description.toLowerCase().includes(searchTerm) ||
      course.tags.some((tag) => tag.toLowerCase().includes(searchTerm))
    );
  });

  function logGPTSelection(title) {
    posthog.capture("View", { property: title }); // Log to PostHog
    updateGPTViews(title); // Add view to Firebase
  }

  return (
    <div className="">
      <NavBar />

      <div className="flex flex-row justify-center items-center">
        <h1 className="mt-20 text-center text-5xl font-semibold">AP GPTs</h1>
      </div>
      <p className="mt-2 text-center text-xl font-normal px-6">
        AI agents to help you study for your AP classes and exams.
      </p>
      <div className="flex flex-row justify-center items-center">
        <input
          className="mt-4 pl-4 pr-2 py-2 border-2 border-solid border-black w-1/2 text-md font-light rounded-3xl"
          placeholder="Search for a course or look below"
          onChange={handleSearchChange}
        ></input>
      </div>
      <div className="flex flex-row justify-center items-center">
        {filteredCourses.length === 0 ? (
          <p className="text-center font-normal text-lg mt-8">
            No results. Try a new query <b>or</b> request a new course on the
            Discord.
          </p>
        ) : (
          <div className="ml-10 mr-10 mt-10 grid md:grid-cols-3 gap-4 sm:grid-cols-2">
            {filteredCourses.map((key) => {
              const course = courseInfo[key];
              if (!course) return null; // Skip rendering if course is undefined

              return (
                <div
                  onClick={() => logGPTSelection(course?.title)}
                  className="rounded-2xl hover:cursor-pointer hover:bg-gray-50 hover:-translate-y-1 duration-300 ease-in-out border border-solid border-gray-300 p-6"
                  key={key}
                >
                  <a
                    href={course?.gpt_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="w-12 h-auto border-2 border-solid border-gray-300 rounded-2xl"
                      src={course?.image}
                      alt={course?.title}
                    />
                    <h1 className="mt-4 text-xl leading-6">{course?.title}</h1>
                    <p className="text-md mt-2 leading-4">
                      {course?.description}
                    </p>
                    <p className="mt-4 underline">{course?.views} views</p>
                  </a>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="flex flex-row justify-center items-center">
        <div>
          <a
            href="https://discord.gg/aBvAvdmrgg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex flex-col items-center hover:cursor-pointer hover:-translate-y-1 duration-300 ease-in-out">
              <img
                src="https://i.imgur.com/xkW9YKW.png"
                alt="discord logo"
                className="mt-12 w-16 h-auto"
              />
              <p className="text-center mb-12 mt-2 text-sm">
                Join the Discord!
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
