import React from 'react';
import { useNavigate } from "react-router-dom";

export default function NavBar({}){

    const navigate = useNavigate('');
    function navigateTo(location){
        navigate(location);
    }

    return(
        <div className="mx-4 h-auto flex flex-row justify-between items-center p-4 border-b-2 border-gray-200 border-solid">
        <div className='flex flex-row items-center hover:text-blue-700 hover:cursor-pointer' onClick={() => navigateTo('/')}>
          <img src="https://i.imgur.com/j30R1fh.png" className='w-10 h-auto rounded-md' />
            <h1 
          className="ml-3 text-xl font-semibold duration-300 ease-in-out"
          onClick={() => navigateTo('/')}
          >AP GPTs</h1>
        </div>
        {/* <h1 
          className="text-xl font-semibold hover:text-blue-700 duration-300 ease-in-out hover:cursor-pointer"
          onClick={() => navigateTo('/')}
          >AP GPTs</h1> */}
        <p 
          className="hover:cursor-pointer hover:text-black text-gray-700 duration-300 ease-in-out"
          onClick={() => navigateTo('/howitworks')}
          >How it works</p>
        </div>
    )
}