import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, get } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyC6EJO1sD1ueeZx1sEt0fB-hN2ftTNOA6M",
  authDomain: "ap-gpts.firebaseapp.com",
  databaseURL: "https://ap-gpts-default-rtdb.firebaseio.com",
  projectId: "ap-gpts",
  storageBucket: "ap-gpts.appspot.com",
  messagingSenderId: "582049640226",
  appId: "1:582049640226:web:952862651997b38ae0ff7b",
  measurementId: "G-6K8HMEY2KL",
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export async function updateGPTViews(gpt){
    
    let gptRef = ref(database, gpt)
    try {
        let snapshot = await get(gptRef);
        let data = snapshot.val();
        
        if (data === null) {
            // If data doesn't exist, create it as an object with a view property
            data = { view: 1 };
        } else {
            // If data exists, increment the view count
            data.view = (data.view || 0) + 1;
        }

        await set(gptRef, data);

        } catch (error) {
            console.error(`Error incrementing view value for ${gpt}`, error);
        }

}

export async function getGPTViews(){

    let gptRef = ref(database)
    try {
        let snapshot = await get(gptRef);
        let data = snapshot.val();
        return data;

        } catch (error) {
            console.error(`Error getting GPT views.`, error);
        }
}