import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import HowItWorks from "./Pages/HowItWorks";

function App() {
  

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/home" element={<Home />}/>
        <Route path="*" element={<Home />} />
        <Route path="/howitworks" element={<HowItWorks />} />
      </Routes>
    </Router>
  )
    
}

export default App;
