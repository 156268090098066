import React from "react";
import NavBar from "./NavBar";

export default function HowItWorks({}) {
  function openDiscordInNewTab() {
    window.open(
      "https://discord.gg/aBvAvdmrgg",
      "_blank",
      "noopener,noreferrer"
    );
  }

  return (
    <div className="">
      <NavBar />
      <h1 className="mt-20 text-center text-5xl font-semibold">How it works</h1>
      <div className="mt-8 md:mx-32 sm:mx-16 mx-16">
        <p className="text-lg">
          Each 'AP GPT' is a chatbot, powered by OpenAI, that is customized to
          assist you with a specific AP course. You can think of your AP GPT as
          a classmate, tutor, or your instructor (if you are enrolled in an AP
          course). Each chatbot has unique functionality and capabilities based
          on the course you need help with. You can view a complete list of
          features for each AP GPT simply by asking it.
        </p>

        <p className="mt-8 text-lg">
          Your GPT has access to a corpus of materials specific to your AP
          course, including but not limited to: course textbooks, preparation
          and review books, College Board student and teacher guides, and other
          resources. This helps the chatbot produce accurate and exam-relevant
          responses.
        </p>
        <p className="mt-9 text-lg">
          All AP GPTs are capable of the following:
        </p>
        <ul className="mt-1 list-inside list-disc ml-2 text-md">
          <li>
            Providing clarification on general course questions and concepts.
          </li>
          <li>Generating realistic multiple-choice and essay questions.</li>
          <li>
            Evaluating your proficiency in certain content areas for the course.
          </li>
          <li>
            Brainstorming and practicing exam strategies in preparation for the
            exam.
          </li>
          <li>Writing course-relevant content.</li>
        </ul>
        <p className="mt-8 text-lg">
          If you have a question about what your AP GPT can do, ask it! Or, join
          our{" "}
          <span
            className="text-[#5b209a] hover:underline hover:cursor-pointer duration-200 ease-in-out hover:text-[#36135a]"
            onClick={openDiscordInNewTab}
          >
            {" Discord."}
          </span>
        </p>
      </div>
      <div className="mt-12 flex flex-row justify-center items-center">
        <div>
          <a
            href="https://discord.gg/aBvAvdmrgg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex flex-col items-center hover:cursor-pointer hover:-translate-y-1 duration-300 ease-in-out">
              <img
                src="https://i.imgur.com/xkW9YKW.png"
                alt="discord logo"
                className="mt-12 w-16 h-auto"
              />
              <p className="text-center mb-20 mt-2 text-sm">
                Join the Discord!
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
